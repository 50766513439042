import React from 'react' 
import styled from '@emotion/styled/macro'
import { Theme2, Device } from "../Layout"

const InteriorContentContainer = styled.div`

    .title{
        font-size: 25px;
        line-height: 1.1;
        display: inline-block;
  font-family: ${Theme2.fonts.headings};
  margin: 0.75em 0;
  font-weight: 700;
  @media ${Device.md}{
    font-size: calc(25px + 9 * ((100vw - 768px) / 432));
  }
  @media ${Device.xl}{
    font-size: 34px;
  }
    }
`

const InteriorContent = ({content}) =>{
    return(
<>
<InteriorContentContainer dangerouslySetInnerHTML={{__html: content}} />
</>
    )
}
export default InteriorContent