import React, { Suspense, lazy } from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled/macro"
import { Seo } from "../components/Seo"
import { Layout, Theme2, Device, Container } from "../components/Layout"
import { HeaderDesktop } from "../components/HeaderDesktop"
import { HeaderMobile } from "../components/HeaderMobile"
import { InteriorContent } from "../components/InteriorContent"
import { RedLine, PaginationPrev } from "../components/SVGs"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { BackToTop } from "../components/BackToTop"

const InteriorPageCallout = lazy(() =>
  import("../components/InteriorPageCallout").then(module => ({
    default: module.InteriorPageCallout,
  }))
)
const Footer = lazy(() =>
  import("../components/Footer").then(module => ({
    default: module.Footer,
  }))
)

const FeaturedImageContainer = styled.div`
  position: relative;
  padding-bottom: 2rem;
  text-align: center;
  margin: 110px 0 58px 0;

  img {
    max-width: 100%;
    @media (min-width: 1090px) {
      max-width: 1000px;
    }
  }
`
const RedSquare = styled.div`
  background-color: ${Theme2.colors.tertiary};
  width: 441px;
  min-height: 205px;
  max-width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
`
const Title = styled.div`
  position: relative;
  font-size: 35px;
  line-height: auto;
  font-family: ${Theme2.fonts.headings};
  font-weight: 700;
  margin: 0.75em 0;
  @media ${Device.md} {
    font-size: calc(35px + 17 * ((100vw - 768px) / 432));
  }
  @media ${Device.xl} {
    font-size: 52px;
  }
`
const RedLineContainer = styled.div`
  display: none;
  @media ${Device.xxl} {
    display: inline;
    position: absolute;
    top: -15px;
    left: -1740px;
    overflow-x: hidden;
    overflow-y: visible;
    box-sizing: border-box;
  }
`
const InteriorPageCalloutContainer = styled.div`
  margin: 118px 0 70px 0;
`
const InteriorPageContainer = styled.div`
  main {
    margin: 5em 0 2em 0;
  }

  @media ${Device.lg} {
    max-width: 1100px;
    margin: 0 auto;

    main {
      margin: 5em 0 7em 0;
    }
  }
`
const LinkStyled = styled(Link)`
  padding: 23px 35px;
  background-color: ${Theme2.colors.neutral};
  text-transform: uppercase;
  color: ${Theme2.colors.dark1};
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 118px;
  display: inline-block;

  svg {
    margin-right: 1rem;
  }
  :hover {
    background-color: ${Theme2.colors.light2};
  }
`
const BlogPost = ({ data }) => {
  const featuredimage =
    data.wpPost.featuredImage !== null
      ? data.wpPost.featuredImage.node.localFile.childImageSharp.gatsbyImageData
          .images.sources[1].srcSet
      : ``
  const seo = data.wpPost.seo

  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      <Seo data={seo} page="blogPost" />
      <Layout>
        <HeaderMobile />
        <HeaderDesktop />
        <Container>
          <InteriorPageContainer>
            <main>
              {data.wpPost.featuredImage !== null && (
                <FeaturedImageContainer>
                  <img
                    srcSet={featuredimage}
                    alt={
                      data.wpPost.blogpostsfeaturedimage
                        .blogPostsFeaturedImageAltText
                    }
                  />
                  <RedSquare />
                </FeaturedImageContainer>
              )}

              <Title>
                <RedLineContainer>
                  <RedLine />
                </RedLineContainer>
                {data.wpPost.title}
              </Title>
              <InteriorContent content={data.wpPost.content} />

              <InteriorPageCalloutContainer>
                <Suspense fallback="loading">
                  <InteriorPageCallout />
                </Suspense>
              </InteriorPageCalloutContainer>

              <LinkStyled to="/blog/">
                <PaginationPrev color={Theme2.colors.tertiary} />
                Back to Blog
              </LinkStyled>
            </main>
          </InteriorPageContainer>
        </Container>
        <Suspense fallback="loading">
          <Footer />
        </Suspense>

        <BackToTop />
      </Layout>
    </>
  )
}
export const query = graphql`
  query individualBlogPostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      blogpostsfeaturedimage {
        blogPostsFeaturedImageAltText
      }
      author {
        node {
          firstName
          lastName
        }
      }
      title
      content
      uri
      date(formatString: "LL")
      modified(formatString: "LL")
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default BlogPost
